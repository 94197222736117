import { createContext, useState } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState('')
  const [searchedItems, setSearchedItems] = useState([])

  return (
    <AuthContext.Provider
      value={{ user, setUser, searchedItems, setSearchedItems }}
    >
      {children}
    </AuthContext.Provider>
  )
}
