import { createSlice } from '@reduxjs/toolkit'

import {
  getProduct,
  getCategories,
  getProductbyID,
  getProductbyIDSub,
} from './productActions'

const productSlice = createSlice({
  name: 'product',
  initialState: {
    product: [],
    isLoading: true,
    categories: [],
  },

  extraReducers: {
    [getProduct.pending]: (state) => {
      return {
        ...state,
        product: [],
        isLoading: true,
      }
    },
    [getProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        product: action.payload,
        isLoading: false,
      }
    },

    [getProductbyID.pending]: (state) => {
      return {
        ...state,
        product: [],
        isLoading: true,
      }
    },

    [getProductbyID.fulfilled]: (state, action) => {
      return {
        ...state,

        product: action.payload,
        isLoading: false,
      }
    },

    [getProductbyIDSub.pending]: (state) => {
      return {
        ...state,
        product: [],
        isLoading: true,
      }
    },

    [getProductbyIDSub.fulfilled]: (state, action) => {
      return {
        ...state,
        product: action.payload,
        isLoading: false,
      }
    },

    [getCategories.fulfilled]: (state, action) => {
      return {
        ...state,
        categories: action.payload,
        isLoading: false,
      }
    },
  },
})

export const { empty } = productSlice.actions

export default productSlice.reducer
