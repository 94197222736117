import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],

  reducers: {
    add: (state, action) => {
      const product = action.payload;

      const exist = state.find((x) => x.id === product.id);

      if (exist) {
        return state.map((x) =>
          x.id === product.id ? { ...exist, amount: exist.amount + 1 } : x
        );
      } else {
        return [...state, { ...product, amount: 1 }];
      }
    },
    remove: (state, action) => {
      const product = action.payload;

      const exist = state.find((x) => x.id === product.id);
      if (exist.amount === 1) {
        return state.filter((x) => x.id !== product.id);
      } else {
        return state.map((x) =>
          x.id === product.id ? { ...exist, amount: exist.amount - 1 } : x
        );
      }
    },
    empty: (state) => {
      return [];
    },
  },
});

export const { add, remove, empty } = cartSlice.actions;

export default cartSlice.reducer;
