import React, { useContext } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from './styles'

import logo from '../../img/logo.png'
import Drawer from '../Drawer/Drawer'
import { Box, Badge } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AuthContext } from '../../PrivateRoute/AuthProvider'

export default function NavTop() {
  const history = useHistory()
  const { setSearchedItems } = useContext(AuthContext)

  const classes = useStyles()
  const cart = useSelector((state) => state.cart)

  const { product } = useSelector((state) => state.product)

  let searchByTitle = (key) =>
    product.filter(({ title }) =>
      title.toLowerCase().includes(key.toLowerCase())
    )

  const handleSearch = (e) => {
    const enterKey = 13

    if (e.which === enterKey && e.target.value) {
      setSearchedItems(searchByTitle(e.target.value))
      history.push('/search')
    }
  }

  return (
    <Toolbar className={classes.toolbar}>
      <Box className={classes.drawerButton}>
        <Drawer />
      </Box>
      <IconButton
        // onClick={() => setDarkmode((prev) => !prev)}
        className={classes.menuButton}
        component={Link}
        to='/'
      >
        <img height={40} src={logo} alt='' />
      </IconButton>

      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder='Search…'
          onKeyDown={handleSearch}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      <Box display='flex' justifyContent='center' alignItems='center'>
        <IconButton
          className={classes.cartButton}
          edge='start'
          color='inherit'
          aria-label='open drawer'
          component={Link}
          to='/cart'
        >
          <Badge badgeContent={cart.length} color='secondary'>
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Box>
    </Toolbar>
  )
}
