import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import SingleCard from './SingleCard'
import { useParams } from 'react-router-dom'
import Animate from '../Animate'
import useProductbyIDSub from '../../hooks/useProductbyIDSub'

const DynamicCardsSub = () => {
  const { id } = useParams()

  const product = useProductbyIDSub(id)

  if (!product.length)
    return (
      <Box my={3}>
        <CircularProgress size={50} />
      </Box>
    )
  return (
    <Container maxWidth='xl'>
      <Box mt={3}>
        <Typography align='left' variant='h2'>
          {id}
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>
      <Box py={5}>
        <Grid container spacing={5}>
          {product.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={3}>
              <Animate anim='zoom-in-up'>
                <SingleCard item={item} />
              </Animate>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default DynamicCardsSub
