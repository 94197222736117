import { createSlice } from '@reduxjs/toolkit'

import { getCustomer, createCustomer, getCarousel } from './customerActions'

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customer: [],
    carousel: {},
    isLoading: true,
  },

  extraReducers: {
    [getCustomer.pending]: (state) => {
      return {
        ...state,
        isLoading: true,
      }
    },
    [getCustomer.fulfilled]: (state, action) => {
      return {
        ...state,
        customer: action.payload,
        isLoading: false,
      }
    },

    [createCustomer.fulfilled]: (state, action) => {
      return {
        ...state,
      }
    },

    [getCarousel.fulfilled]: (state, action) => {
      return {
        ...state,
        carousel: action.payload,
        isLoading: false,
      }
    },
  },
})

export default customerSlice.reducer
