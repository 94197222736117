import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'

import CardContent from '@material-ui/core/CardContent'

import Typography from '@material-ui/core/Typography'
import Color from 'color'
import { format, parseISO } from 'date-fns'

import { Box, Divider } from '@material-ui/core'
import BasicTable from './Table'

const useStyles = makeStyles({
  root: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: `0 6px 12px 0 ${Color('grey')
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
})

export default function SingleCard({ item }) {
  const classes = useStyles()

  const { name, phone_number, address, purchase_information, timestamp } = item

  const total = purchase_information.reduce((a, c) => a + c.price * c.amount, 0)

  return (
    <Card className={classes.root} align='left'>
      <CardActionArea>
        <CardContent>
          <Typography align='center' gutterBottom variant='h5' component='h2'>
            Customer Name: {name}
          </Typography>

          <Typography
            align='center'
            className={classes.description}
            variant='subtitle1'
            color='textSecondary'
          >
            Number: {phone_number}
          </Typography>
          <Typography
            align='center'
            className={classes.description}
            variant='subtitle1'
            color='textSecondary'
          >
            Address: {address}
          </Typography>
          <Typography
            align='center'
            className={classes.description}
            variant='subtitle1'
            color='textSecondary'
          >
            Ordered at: {format(parseISO(timestamp), 'PPpp')}
          </Typography>
          <br />
          <Typography
            align='center'
            className={classes.description}
            variant='h6'
            color='textPrimary'
          >
            Orders:
          </Typography>

          <Divider />
        </CardContent>
        <BasicTable order={purchase_information} />
        <Box m={2}>
          <Typography
            align='right'
            className={classes.description}
            variant='h6'
            color='textPrimary'
          >
            Total Amount: {total}৳
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}
