import axios from 'axios'

const API = axios.create({
  baseURL: 'https://admin.starhardware.co/api',
})

export const getProduct = () => API.get('/products/all/')

export const getProductbyID = (id) => API.get(`/products/category/${id}/`)

export const getProductbyIDSub = (id) => API.get(`/products/subcategory/${id}/`)

export const getCategories = () => API.get('/products/category/')

export const getCustomer = () => API.get('/get_customer/')

export const getCarousel = () => API.get('/products/get_carousel/')

export const createCustomer = (user) => API.post('/create_customer/', user)
