import { Card, CardActionArea, CardMedia } from '@material-ui/core'
import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import useStyles from './styles'

// const handleDragStart = (e) => e.preventDefault()

const Carousel = ({ carouselImg, setMainImg }) => {
  const classes = useStyles()

  const items = carouselImg.map((image) => (
    <Card
      onClick={() => setMainImg(image)}
      elevation={0}
      className={classes.root}
      align='center'
    >
      <CardActionArea>
        <CardMedia className={classes.media} image={image} />
      </CardActionArea>
    </Card>
  ))

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  }

  return (
    <AliceCarousel
      paddingLeft={50}
      paddingRight={50}
      autoPlayStrategy='none'
      autoPlayInterval={2500}
      animationDuration={1000}
      infinite
      mouseTracking
      disableDotsControls
      disableButtonsControls
      responsive={responsive}
      autoPlay
      items={items}
    />
  )
}

export default Carousel
