import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import SingleCard from './SingleCard'
import Animate from '../Animate'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getProduct } from '../../redux/productActions'

const Cards = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getProduct())
  }, [dispatch])

  const { product } = useSelector((state) => state.product)

  console.log('product', product)

  if (!product)
    return (
      <Box my={3}>
        <CircularProgress size={50} />
      </Box>
    )

  const firstTen = product.slice(0, 12)

  return (
    <Container maxWidth='xl'>
      <Box mt={3}>
        <Typography align='left' variant='h2'>
          Collections
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>
      <Box py={5}>
        <Grid container spacing={5}>
          {firstTen.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={3}>
              <Animate anim='zoom-in-up'>
                <SingleCard item={item} />
              </Animate>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default Cards
