import { Box, Container, Divider, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cards from '../components/AdminCards/Cards'
import { getCustomer } from '../redux/customerActions'

const Admin = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCustomer())
  }, [dispatch])

  const { customer } = useSelector((state) => state.customer)

  return (
    <Container>
      <Box my={3}>
        <Typography align='left' variant='h2'>
          Admin
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>
      <Cards customer={customer} />
    </Container>
  )
}

export default Admin
