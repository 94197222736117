import {
  Box,
  Container,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import one from '../img/clients/1.png'
import two from '../img/clients/2.jpg'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
  },
}))

const About = () => {
  const classes = useStyles()
  return (
    <Container>
      <Box my={3}>
        <Typography align='left' variant='h2'>
          STAR HARDWARE
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>
      <Typography align='left' variant='h5' color='textSecondary'>
        Make your dream home with trusted hardware fittings
      </Typography>
      <Box my={3}>
        <Paper className={classes.paper}>
          <Box p={2} bgcolor='#71c9eb7e'>
            <Typography align='left' variant='h6'>
              About
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <Typography align='left' variant='body1' color='textSecondary'>
              Star Hardware is one of the leading hardware stores in Bangladesh
              since 1984. Since then it has had one purpose to bring to the
              Bangladesh market the ultimate one-stop-shop for the latest
              solutions for home improvement.
              <br />
              <br />
              We have an extraordinary blend of both conventional and modern day
              hardware to suit each need of our clients. Our customer base
              varies from home owners, architects, Developers as well as
              interior designers .What separates us from our competitors is the
              quality of our items. The owner of star hardware Mr.Gaziur rahman
              believes Quality is always more economical in the long run. Our
              main purpose is to provide absolute best quality hardware
              products. We have a great imported collection of hardware fittings
              from Taiwan, China & Italy. Be it a handle lock, door closer or
              Hinge all of them are high quality products materials used such as
              brass or stainless steel and have a great finishing providing
              guarantee of the products as well.
              <br />
              <br />
              The exciting part is you need not go around looking for the best
              fixtures, we bring it to you in the comfort of your home. Clients
              can choose from wide scope of products and place their orders via
              our website.
              <br />
              <br />
              Consumer satisfaction is our first concern and endeavor to serve
              them the best we have. We center around giving the best and
              dependable hardware items to guarantee our relationship with the
              client additionally keeps going longer.
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box my={3}>
        <Paper className={classes.paper}>
          <Box p={2} bgcolor='#71c9eb7e'>
            <Typography align='left' variant='h6'>
              Our Top Clients
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <img height={150} src={one} alt='one' />
            <img height={150} src={two} alt='two' />
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default About
