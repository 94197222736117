import { configureStore } from '@reduxjs/toolkit'

import cartReducer from './cartSlice'
import productReducer from './productSlice'
import customerReducer from './customerSlice'

export default configureStore({
  reducer: {
    cart: cartReducer,
    product: productReducer,
    customer: customerReducer,
  },
})
