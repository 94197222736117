import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List'
// import { Divider } from '@material-ui/core'

import DrawerListItem from './DrawerListItem'
import useCategory from '../../hooks/useCategory'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function DrawerList({ toggleDrawer }) {
  const classes = useStyles()

  const [active, setActive] = useState('')

  const catagories = useCategory()

  return (
    <List
      component='nav'
      aria-labelledby='nested-list-subheader'
      //   subheader={
      //     <ListSubheader component='div' id='nested-list-subheader'>
      //       Nested List Items
      //     </ListSubheader>
      //   }
      className={classes.root}
    >
      {catagories.map((category) => (
        <DrawerListItem
          title={category.name}
          data={category.sublist}
          active={active}
          setActive={setActive}
          toggleDrawer={toggleDrawer}
        />
      ))}
    </List>
  )
}
