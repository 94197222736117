import React from 'react'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'

const Menus = ({ title, data }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  return (
    <>
      <Button
        color='inherit'
        component={Link}
        to={`/product/${title}`}
        {...bindHover(popupState)}
      >
        {title}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {data.map((item) => (
          <MenuItem
            key={item}
            component={Link}
            to={`/sub/${item}`}
            onClick={popupState.close}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Menus
