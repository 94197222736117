import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// import Divider from '@material-ui/core/Divider'
import { Box, Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import Menus from '../menus/Menus'
import useCategory from '../../hooks/useCategory'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),

    '&  button': {
      color: grey[100],
    },
  },
  bottomNav: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default function NavBottom() {
  const classes = useStyles()

  const catagories = useCategory()
  if (!catagories) return null

  return (
    <Box className={classes.bottomNav}>
      <Grid container alignItems='center' className={classes.root}>
        {catagories.map((category) => (
          <Menus
            key={category.id}
            title={category.name}
            data={category.sublist}
          />
        ))}

        {/* <Menus title='Door lock' data={menuData.one} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Security lock' data={menuData.two} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Door accessories' data={menuData.three} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Furniture fittings' data={menuData.four} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Hanging door fittings' data={menuData.five} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Shelve fitting' data={menuData.six} />
        <Divider orientation='vertical' flexItem />
        <Menus title='Sanitary fittings' data={menuData.seven} /> */}
      </Grid>
    </Box>
  )
}
