import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getCustomer = createAsyncThunk(
  'customer/getCustomer',
  async () => {
    try {
      const { data } = await api.getCustomer()

      return data
    } catch (error) {
      console.log(error)
    }
  }
)

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async ({ user, history }) => {
    try {
      const { data } = await api.createCustomer(user)
      history.push('/thanks')
      return data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getCarousel = createAsyncThunk(
  'customer/getCarousel',
  async () => {
    try {
      const { data } = await api.getCarousel()

      return data
    } catch (error) {
      console.log(error)
    }
  }
)
