import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'

import Box from '@material-ui/core/Box'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchemea from '../components/HookForm/Schema'
import { createCustomer } from '../redux/customerActions'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function User() {
  const cart = useSelector((state) => state.cart)

  const classes = useStyles()

  const history = useHistory()

  const dispatch = useDispatch()

  if (cart.length === 0) history.push('/')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemea),
  })
  const onSubmit = (data) => {
    const user = {
      name: data.name,
      phone_number: '0' + data.phone,
      address: data.address,
      purchase_information: cart,
    }

    dispatch(createCustomer({ user, history }))
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ShoppingBasketIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Submit Order
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('name')}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='name'
            label='Name'
            name='name'
            autoComplete='name'
            autoFocus
            error={errors.name}
          />
          {/* {errors.name && <span>{errors.name}</span>} */}
          <TextField
            {...register('address')}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='address'
            label='Address'
            name='address'
            autoComplete='address'
            error={errors.address}
          />
          <TextField
            {...register('phone')}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='phone'
            helperText='Valid Number: Ex: 016XXXXXXXX'
            label='Phone Number'
            name='phone'
            autoComplete='phone'
            error={errors.phone}
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  )
}
