import { Box, Grid } from '@material-ui/core'

import SingleCard from './SingleCard'

import Animate from '../Animate'
import { CircularProgress } from '@material-ui/core'

const Cards = ({ customer }) => {
  if (!customer.length)
    return (
      <Box my={3}>
        <CircularProgress size={50} />
      </Box>
    )

  return (
    <Box py={5}>
      <Grid container spacing={5}>
        {customer.map((item) => (
          <Grid key={item.id} item xs={12} sm={12} md={12}>
            <Animate anim='zoom-in-up'>
              <SingleCard item={item} />
            </Animate>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Cards
