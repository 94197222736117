import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Color from 'color'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'

import { Box } from '@material-ui/core'
import Modal from './Modal'
import { useDispatch } from 'react-redux'
import { add } from '../../redux/cartSlice'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles({
  root: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: `0 6px 12px 0 ${Color('grey')
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  },
  media: {
    paddingTop: '70%',
    width: '100%',
    objectFit: 'contain',
    backgroundSize: 'contain',

    // //16:9 ratio
    // paddingTop: '56%',
    // width: '100%',
    // objectFit: 'cover',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '2rem',
  },
})

export default function SingleCard({ item }) {
  const classes = useStyles()

  const {
    title,
    price,
    description,
    first_image,
    second_image,
    third_image,
    fourth_image,
    fifth_image,
  } = item

  let carouselImg = [second_image, third_image, fourth_image, fifth_image]
  carouselImg = carouselImg.filter((item) => item !== null)

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const addToCart = () => {
    dispatch(add(item))
    enqueueSnackbar('Item added to the cart!', { variant: 'success' })
  }

  const [open, setOpen] = useState(false)

  return (
    <Card className={classes.root} align='left'>
      <CardActionArea onClick={() => setOpen(true)}>
        <CardMedia
          className={classes.media}
          image={first_image}
          title='image'
        />
        <CardContent>
          <Box
            style={{ minHeight: '4rem' }}
            display='flex'
            justifyContent='space-between'
          >
            <Typography
              className={classes.title}
              gutterBottom
              variant='h5'
              component='h2'
            >
              {title}
            </Typography>
          </Box>
          <Typography
            className={classes.description}
            variant='body2'
            color='textSecondary'
            component='p'
          >
            {description}
          </Typography>
          <br />
          <Typography variant='body1' color='textPrimary' component='p'>
            Regular Price: {price}৳
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Modal
          item={item}
          carouselImg={carouselImg}
          open={open}
          setOpen={setOpen}
        />

        <Button
          onClick={addToCart}
          endIcon={<AddShoppingCartIcon />}
          size='small'
          color='primary'
        >
          Add to Cart
        </Button>
      </CardActions>
    </Card>
  )
}
