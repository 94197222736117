import {
  AppBar,
  Grid,
  makeStyles,
  Typography,
  List,
  ListItem,
  Container,
  Box,
  IconButton,
  Divider,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../img/logo.png'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
    textAlign: 'center',

    '&  button': {
      color: grey[100],
    },
  },
  footer: {
    zIndex: 5,
  },
  div: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  saif: {
    color: '#fff',

    fontWeight: 'bold',
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <AppBar position='static' className={classes.footer}>
      <Container maxWidth='md'>
        <Grid
          container
          justify='center'
          alignItems='center'
          wrap='wrap'
          className={classes.root}
          spacing={3}
        >
          <Grid item xs={12} md={5}>
            <Box my={3}>
              <img height={70} src={logo} alt='logo' />
              <Box my={1}>
                <IconButton
                  size='small'
                  style={{ color: '#fff' }}
                  href='https://www.facebook.com/starhardwarebd/'
                  target='blank'
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  size='small'
                  style={{ color: '#fff' }}
                  href='https://www.linkedin.com/company/jmstar-hardware'
                  target='blank'
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.div} item xs={12} md={1}>
            <Divider orientation='horizontal' />
          </Grid>
          <Grid item xs={12} md={5}>
            <List>
              <ListItem component={Link} to='/about' button>
                About
              </ListItem>
              <ListItem component={Link} to='/contact' button>
                Contact
              </ListItem>
              <ListItem component={Link} to='/admin' button>
                Admin
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>

      <Divider />
      <Typography
        style={{ padding: '10px 5px' }}
        variant='body2'
        align='center'
      >
        Copyright © 2021{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://odileon.com/'
          className={classes.saif}
        >
          Odileon
        </a>
        . All rights reserved.
      </Typography>
    </AppBar>
  )
}

export default Footer
