import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default function BasicTable({ order }) {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align='center'>Quantity</TableCell>
            <TableCell align='right'>Price</TableCell>
            <TableCell align='right'>Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.map((row, i) => (
            <TableRow key={i}>
              <TableCell component='th' scope='row'>
                {row.title}
              </TableCell>
              <TableCell align='center'>{row.amount}</TableCell>
              <TableCell align='right'>{row.price}৳</TableCell>
              <TableCell align='right'>{row.price * row.amount}৳</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
