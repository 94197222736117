import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

import { getCarousel } from '../redux/customerActions'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom, Autoplay])

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '1rem',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
    },
  },
}))

const Carousel = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCarousel())
  }, [dispatch])

  const { carousel } = useSelector((state) => state.customer)

  if (!carousel)
    return (
      <Box my={3}>
        <CircularProgress size={50} />
      </Box>
    )

  const { first_image, second_image, third_image, fourth_image, fifth_image } =
    carousel[0] || []

  let carouselImg = [
    first_image,
    second_image,
    third_image,
    fourth_image,
    fifth_image,
  ]

  carouselImg = carouselImg.filter((item) => item !== null)

  return (
    <Box className={classes.root}>
      <Swiper
        loop
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        spaceBetween={20}
        navigation
        pagination={{ clickable: true }}
      >
        {carouselImg.map((img, i) => (
          <SwiperSlide key={i}>
            <Paper
              className={classes.paper}
              // style={{
              //   backgroundImage: `url(${demo})`,
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center',
              //   color: '#fff',
              //   minHeight: '30rem',
              // }}
            >
              <img style={{ width: '100%' }} src={img} alt='' />
            </Paper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default Carousel
