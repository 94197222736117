import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getProduct = createAsyncThunk('product/getProduct', async () => {
  try {
    const { data } = await api.getProduct()

    return data
  } catch (error) {
    console.log(error)
  }
})

export const getProductbyID = createAsyncThunk(
  'product/getProductbyID',
  async (id) => {
    try {
      const { data } = await api.getProductbyID(id)
      return data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getProductbyIDSub = createAsyncThunk(
  'product/getProductbyIDSub',
  async (id) => {
    try {
      const { data } = await api.getProductbyIDSub(id)
      return data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getCategories = createAsyncThunk(
  'product/getCategories',
  async () => {
    try {
      const { data } = await api.getCategories()
      return data
    } catch (error) {
      console.log(error)
    }
  }
)
