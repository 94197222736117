import { Container } from '@material-ui/core'
import Cards from '../components/SearchCards/Cards'

const Search = () => {
  return (
    <Container>
      <Cards />
    </Container>
  )
}

export default Search
