import {
  Box,
  Typography,
  Divider,
  Container,
  Paper,
  makeStyles,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
  },
}))

const Contact = () => {
  const classes = useStyles()
  return (
    <Container>
      <Box my={3}>
        <Typography align='left' variant='h2'>
          Contact Us
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>

      <Box my={3}>
        <Paper className={classes.paper}>
          <Box p={2} bgcolor='#71c9eb7e'>
            <Typography align='left' variant='h6'>
              Contact us
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <Typography align='left' variant='body1' color='textSecondary'>
              Address: 75 Green Rd, Farmgate, 1205 Tejgaon Dhaka Division,
              Bangladesh
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <Typography align='left' variant='body1' color='textSecondary'>
              Contact: +880258152150, +8801870212464, +8801787749508
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <Typography align='left' variant='body1' color='textSecondary'>
              Email: jm.starhardware@gmail.com
            </Typography>
          </Box>
        </Paper>
      </Box>
      <div style={{ width: '100%' }}>
        <iframe
          title='Contact'
          width='100%'
          height='600'
          frameborder='0'
          scrolling='no'
          marginheight='0'
          marginwidth='0'
          src='https://maps.google.com/maps?q=75%20Green%20Rd,%20Farmgate,%201205%20Tejgaon%20%20Dhaka%20Division,%20Bangladesh&t=&z=13&ie=UTF8&iwloc=&output=embed'
        ></iframe>
      </div>
    </Container>
  )
}

export default Contact
