import {
  Paper,
  Box,
  Typography,
  makeStyles,
  IconButton,
  Grow,
  Grid,
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import CartImg from './CartImg'
import { useDispatch } from 'react-redux'
import { add, remove } from '../../redux/cartSlice'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '2rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  cartBoxD: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cartBoxDBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #3C8DAD',
    borderRadius: 15,
    height: 'auto',
  },
  cartBoxM: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  cartBoxMBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #3C8DAD',
    borderRadius: 15,
    height: 'auto',
  },

  price: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}))

const CartItem = ({ item }) => {
  const { first_image, title, amount, price } = item
  const classes = useStyles()

  const dispatch = useDispatch()
  return (
    <Grow in>
      <Paper className={classes.paper}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={4} md={4} className={classes.titleBox}>
            <CartImg img={first_image} />
            <Typography variant='h5' className={classes.title}>
              {title}
            </Typography>
          </Grid>

          {/* Desktop */}
          <Grid item xs={4} md={4} className={classes.cartBoxD}>
            <Box className={classes.cartBoxDBox}>
              <IconButton
                onClick={() => dispatch(remove(item))}
                color='secondary'
              >
                <RemoveIcon />
              </IconButton>
              <Typography variant='h6'>{amount}</Typography>
              <IconButton onClick={() => dispatch(add(item))} color='primary'>
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          {/* Mobile */}
          <Grid item xs={4} md={4} className={classes.cartBoxM}>
            <Box className={classes.cartBoxMBox}>
              <IconButton
                size='small'
                onClick={() => dispatch(remove(item))}
                color='secondary'
              >
                <RemoveIcon />
              </IconButton>
              <Typography variant='body1'>{amount}</Typography>
              <IconButton
                size='small'
                onClick={() => dispatch(add(item))}
                color='primary'
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box mr={1}>
              <Typography className={classes.price} align='right' variant='h6'>
                Price: {price * amount}৳
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  )
}

export default CartItem
