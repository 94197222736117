import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import cyan from '@material-ui/core/colors/cyan'
import red from '@material-ui/core/colors/red'
import { SnackbarProvider } from 'notistack'

const useStyles = makeStyles({
  success: { backgroundColor: cyan[700] },
})

const MyTheme = ({ darkmode, children }) => {
  const theme = createMuiTheme({
    palette: {
      type: darkmode ? 'dark' : 'light',
      primary: {
        main: '#3C8DAD',
      },
      secondary: red,
    },
    typography: {
      fontFamily: 'Quicksand',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  })

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        classes={{
          variantSuccess: classes.success,
        }}
      >
        <CssBaseline>{children}</CssBaseline>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default MyTheme
