import {
  Box,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import SingleCard from './SingleCard'
import Animate from '../Animate'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect } from 'react'
import { getProduct } from '../../redux/productActions'
import { AuthContext } from '../../PrivateRoute/AuthProvider'

const Cards = () => {
  const { searchedItems } = useContext(AuthContext)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getProduct())
  }, [dispatch])

  const { product } = useSelector((state) => state.product)

  console.log('product', product)

  if (searchedItems.length === 0) {
    return (
      <Box my={3}>
        <Box mt={3}>
          <Typography align='left' variant='h2'>
            Searched Items
          </Typography>
          <LinearProgress />
        </Box>

        <Box my={3}>
          <Typography align='left' variant='h4'>
            No items found with that keyword
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Container maxWidth='xl'>
      <Box mt={3}>
        <Typography align='left' variant='h2'>
          Searched Items
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>
      <Box py={5}>
        <Grid container spacing={5}>
          {searchedItems.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={3}>
              <Animate anim='zoom-in-up'>
                <SingleCard item={item} />
              </Animate>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default Cards
