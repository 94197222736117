import { Container, Box, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import CartItem from '../components/Cart/CartItem'
import CartTotal from '../components/Cart/CartTotal'

const CartScreen = () => {
  const cart = useSelector((state) => state.cart)
  return (
    <Container>
      <Box my={3}>
        <Typography align='left' variant='h2'>
          Cart Items:
        </Typography>
        <Divider
          style={{ height: 3, borderRadius: 10, background: '#3C8DAD' }}
        />
      </Box>

      {cart?.map((item, i) => (
        <CartItem key={i} item={item} />
      ))}

      <CartTotal cart={cart} />
    </Container>
  )
}

export default CartScreen
