import { Container, makeStyles } from '@material-ui/core'
import Cards from '../components/Cards/Cards'
import Carousel from '../components/Carousel'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
}))

const Home = () => {
  const classes = useStyles()
  return (
    <Container
      className={classes.root}
      maxWidth='xl'
      disableGutters
      align='center'
    >
      <Container>
        <Carousel />
      </Container>
      <Cards />
    </Container>
  )
}

export default Home
