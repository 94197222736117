import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProductbyIDSub } from '../redux/productActions'

const useProductbyIDSub = (id) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getProductbyIDSub(id))
  }, [dispatch, id])

  const { product } = useSelector((state) => state.product)

  return product
}

export default useProductbyIDSub
