import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
// import Slide from '@material-ui/core/Slide';
import { Typography, Grow } from '@material-ui/core'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDispatch } from 'react-redux'
import { add } from '../../redux/cartSlice'
import Carousel from './ModalCarousel/Carousel'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction='up' ref={ref} {...props} />
})

export default function Modal({ item, carouselImg, open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = () => {
    setOpen(false)
    dispatch(add(item))
  }

  const { title, first_image, price, description } = item
  const [mainImg, setMainImg] = useState(first_image)

  return (
    <div>
      <Button color='primary' onClick={handleClickOpen}>
        Details
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
        <DialogContent dividers>
          <img
            style={{ width: '100%', borderRadius: 10 }}
            src={mainImg}
            alt='img'
          />
          <Typography variant='body2' color='textSecondary' component='p'>
            {description}
          </Typography>
          <Typography variant='body1' color='textPrimary' component='p'>
            Regular Price: {price}৳
          </Typography>

          {!!carouselImg.length && (
            <Carousel carouselImg={carouselImg} setMainImg={setMainImg} />
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleClose}
            endIcon={<CancelIcon />}
            size='small'
            color='secondary'
          >
            Close
          </Button>
          <Button
            onClick={handleAdd}
            endIcon={<AddShoppingCartIcon />}
            size='small'
            color='primary'
          >
            Add to Cart
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
