import { Box } from '@material-ui/core';
import { Paper, Typography, makeStyles, Button } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '2rem 0',
    padding: 8,
  },
}));

const CartTotal = ({ cart }) => {
  const totalPrice = cart.reduce((a, c) => a + c.price * c.amount, 0);
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.price} align='right' variant='h6'>
        Total Price: {totalPrice}৳
      </Typography>
      <Box mt={3} align='right'>
        <Button
          component={Link}
          to='/user'
          color='primary'
          endIcon={<ShoppingCartIcon />}
          variant='contained'
          disabled={!cart.length}
        >
          Checkout
        </Button>
      </Box>
    </Paper>
  );
};

export default CartTotal;
