import { makeStyles } from '@material-ui/core'
import Color from 'color'

export default makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    transition: '0.2s',
    margin: 5,

    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: `0 6px 12px 0 ${Color('grey')
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  },
  media: {
    height: 250,
    objectFit: 'contain',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
}))
