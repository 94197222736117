import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const DrawerListItem = ({ active, setActive, title, data, toggleDrawer }) => {
  const classes = useStyles()
  return (
    <>
      <ListItem button onClick={() => setActive(title)}>
        <ListItemIcon>
          <InboxIcon color='primary' />
        </ListItemIcon>
        <ListItemText primary={title} />
        {active === title ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={active === title} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItem
            component={Link}
            to={`/product/${title}`}
            key={title}
            button
            className={classes.nested}
            onClick={toggleDrawer('left', false)}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
          {data.map((item) => (
            <ListItem
              component={Link}
              to={`/sub/${item}`}
              key={item}
              button
              className={classes.nested}
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default DrawerListItem
