import { useState } from 'react'
import Home from './screens/Home'
import MyTheme from './MyTheme'
import Navbar from './components/navbar'
import Footer from './components/footer/Footer'
import CartScreen from './screens/CartScreen'
import DynamicCards from './components/DynamicCards/DynamicCards'
import About from './screens/About'
import User from './screens/User'
import Thanks from './components/Cart/Thanks'
import {
  AnimatedRoutes,
  RouteTransition,
} from './components/FramerMotion/RouteTransition'
import Contact from './screens/Contact'
import DynamicCardsSub from './components/DynamicCardsSub/DynamicCardsSub'
import { AuthProvider } from './PrivateRoute/AuthProvider'
import Login from './PrivateRoute/Login'
import PrivateRoute from './PrivateRoute/PrivateRoute'
import Admin from './screens/Admin'
import Search from './screens/Search'

const App = () => {
  const [darkmode, setDarkmode] = useState(false)
  return (
    <MyTheme darkmode={darkmode}>
      <AuthProvider>
        <Navbar setDarkmode={setDarkmode} />
        <AnimatedRoutes exitBeforeEnter initial={false}>
          <RouteTransition slideUp={50} path='/' exact>
            <Home />
          </RouteTransition>
          <RouteTransition path='/cart'>
            <CartScreen />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/user'>
            <User />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/contact'>
            <Contact />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/thanks'>
            <Thanks />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/about'>
            <About />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/search'>
            <Search />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/product/:id'>
            <DynamicCards />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/sub/:id'>
            <DynamicCardsSub />
          </RouteTransition>
          <RouteTransition slideUp={50} path='/login'>
            <Login />
          </RouteTransition>
          <PrivateRoute path='/admin'>
            <Admin />
          </PrivateRoute>
          {/* <Route path='/' component={Home} exact /> */}
          {/* <Route path='/cart' component={CartScreen} /> */}
          {/* <Route path='/user' component={User} /> */}
          {/* <Route path='/thanks' component={Thanks} /> */}
          {/* <Route path='/about' component={About} /> */}
          {/* <Route path='/product/:id' component={DynamicCards} /> */}
        </AnimatedRoutes>
        <Footer />
      </AuthProvider>
    </MyTheme>
  )
}

export default App
